html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-family: "Roboto";
  font-weight: 400;
  color: #6b7280;
  background-color: #fff;
  overflow-x: hidden;
  position: relative;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #dadada;
}

::-webkit-scrollbar-thumb {
  background-color: #4b5563;
  border-radius: 6px;
  background-clip: padding-box;
  border: 3px solid rgba(255, 255, 255, 0);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #6b7280;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0.01em 0 0 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

::selection {
  color: #e5e7eb;
  background-color: #374151;
}

.svc-creator__banner,
.sa-commercial {
  display: none;
}

.anchor {
  scroll-margin-top: 112px;
}

@media (min-width: 992px) {
  .anchor {
    scroll-margin-top: 152px;
  }
}

@media (min-width: 1240px) {
  .anchor {
    scroll-margin-top: 196px;
  }
}
